<template>
  <div class="workdetail">
    <div class="container">
      <div class="nav">
        <div class="DWB" @click="toList">COHERE</div>
        <div class="DWB" @click="tohome">IS HERE</div>
      </div>
      <div class="swiperbox" v-if="detail.imgmodel != 3">
        <swiper
          @slideChange="onSlideChange"
          @swiper="onSwiper"
          :loop="true"
          v-if="imgArr.length"
        >
          <swiper-slide
            class="slider"
            v-for="(item, index) in imgArr"
            :key="index"
            @click="showPreview(item, index)"
            ><div class="box">
              <img
                :src="'https://www.coherehouse.com' + item"
                alt=""
                srcset=""
              /></div
          ></swiper-slide>
        </swiper>
        <!-- <div class="arrowBox">
          <div class="prev" @click="toPrev">
            <img src="~@/assets/img/arrow.png" alt="" srcset="" />
          </div>
          <div class="next" @click="toNext">
            <img src="~@/assets/img/arrow.png" alt="" srcset="" />
          </div>
        </div> -->
      </div>
      <div class="controllist" v-if="detail.imgmodel != 3">
        <ul>
          <li
            :class="index === currentIndex ? 'active' : ''"
            @click="changeIndex(index)"
            v-for="(item, index) in imgArr"
            :key="index"
          >
            <img :src="'https://www.coherehouse.com' + item" alt="" srcset="" />
          </li>
        </ul>
      </div>
      <div class="videobox" v-if="detail.imgmodel == 3">
        <div class="video">
          <video
            class=""
            id="video"
            loop
            muted
            controls
            webkit-playsinline="true"
            playsinline="true"
            x5-playsinline="true"
            x5-video-player-type="h5-page"
            ref="video"
          >
            <source
              :src="'https://www.coherehouse.com' + detail.video"
              type="video/mp4"
            />
          </video>
          <!-- <div class="controls" v-show="showOwnControls" @click.prevent="playEvent">
        <div class="playBtn">
          <img src="~@/assets/img/playbtn.png" alt="" srcset="" />
        </div>
      </div> -->
        </div>
      </div>
      <div class="textbox">
        <div class="introduce t1" v-html="detail.content"></div>
        <div class="introduce t2" v-html="detail.content2"></div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, computed, watch, nextTick } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { imgarr1, imgarr2 } from './imf'
// import SwiperCore, { Thumbs } from 'swiper'
// import { Thumbs } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import type SwiperType from 'swiper/types/swiper-class'
import 'swiper/swiper.scss'

import { ImagePreview } from 'vant'
import 'vant/lib/image-preview/style'
// import '@vant/touch-emulator'
export default defineComponent({
  components: { Swiper, SwiperSlide },
  setup() {
    const currentIndex = ref(0)
    const mySwiper = ref()
    const imgArr = ref([])
    const store = useStore()
    const route = useRoute()

    const id = route.params.id
    store.dispatch('article/getDetailAction', id)
    const detail = computed(() => {
      return store.state.article.detail
    })
    watch(detail, (newval) => {
      console.log(mySwiper.value)
      imgArr.value = newval.tmb4
      nextTick(() => {
        mySwiper.value.slideToLoop(0, 0)
      })
    })

    const changeIndex = (index: number) => {
      mySwiper.value.slideToLoop(index, 0)
    }
    const onSwiper = (swiper: SwiperType) => {
      mySwiper.value = swiper
    }
    const onSlideChange = (swiper: SwiperType) => {
      currentIndex.value = swiper.realIndex
    }

    const picArr = computed(() => {
      return imgArr.value.map((item) => {
        return 'https://www.coherehouse.com' + item
      })
    })

    const showPreview = (item: string, index: number) => {
      ImagePreview({
        images: picArr.value,
        startPosition: index,
        closeable: true,
        showIndicators: true
      })
    }

    const router = useRouter()
    const tohome = () => {
      router.replace({
        name: 'mhome',
        query: {
          isback: 1
        }
      })
    }
    const toList = () => {
      router.replace({
        name: 'mworklist'
      })
    }
    return {
      detail,
      imgArr,
      currentIndex,
      changeIndex,
      onSlideChange,
      onSwiper,
      showPreview,
      toList,
      tohome
    }
  }
})
</script>
<style scoped lang="scss">
.workdetail {
  width: 100%;
  background-color: #000;
  min-height: 100vh;
  padding-top: 100px;
  .container {
    width: 670px;
    margin: 0 auto;
    padding-bottom: 85px;
    .nav {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #fff;
      font-size: 30px;
      margin-bottom: 60px;
      > div {
        cursor: pointer;
      }
    }
    .swiperbox {
      width: 100%;
      position: relative;
      ::v-deep .swiper-wrapper {
        align-items: center;
        // justify-content: center;
      }
      .slider {
        cursor: pointer;
      }
      .box {
        width: 100%;
      }
      .arrowBox {
        position: absolute;
        width: 100%;
        top: 50%;
        > div {
          width: 28px;
          cursor: pointer;
        }
        .prev {
          position: absolute;
          left: -10%;
        }
        .next {
          position: absolute;
          right: -10%;
          transform: rotate(180deg);
        }
      }
    }
    .controllist {
      margin-top: 3vw;
      width: 100%;
      ul {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        gap: 7px;
        li {
          cursor: pointer;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          &::after {
            display: block;
            content: '';
            background-color: rgba(0, 0, 0, 0.65);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
          &.active {
            &::after {
              background-color: rgba(0, 0, 0, 0);
            }
          }
        }
      }
    }
    .videobox {
      width: 100%;
      position: relative;
      .video {
        width: 100%;
        #video {
          width: 100%;
        }
      }
    }
    .introduce {
      font-size: 28px;
      line-height: 36px;
      letter-spacing: 0px;
      color: #fff;
      &:nth-of-type(1) {
        margin-top: 60px;
        margin-bottom: 60px;
      }
    }
    .t1 {
      font-family: 'SimHei' !important;
      font-size: 14px !important;
      ::v-deep span,
      ::v-deep strong,
      ::v-deep p,
      ::v-deep b,
      ::v-deep div {
        font-family: inherit !important;
        font-size: 14px !important;
      }
    }
    .t2 {
      font-family: 'Helvetica' !important;
      font-size: 14px !important;
      ::v-deep span,
      ::v-deep strong,
      ::v-deep p,
      ::v-deep b,
      ::v-deep div {
        font-family: inherit !important;
        font-size: 14px !important;
      }
    }
  }
}
</style>
